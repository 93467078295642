import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EventsStateModel } from "./events.model";
import { Injectable } from "@angular/core";
import { EventsAction } from "./events.action";
import { tap } from "rxjs";
import { EventsService } from "../services/events.service";
import { EVENTS_STATE } from "../../../../state";
import { EventType } from "../../../shared/graphql/generated/graphql";

@State<EventsStateModel>({
    name: EVENTS_STATE,
    defaults: {
      events: [],
      fetching: false,
    }
})
@Injectable()
export class EventsState {

  constructor(private eventsService: EventsService) {}
  @Selector()
  static events(state: EventsStateModel) {
    return state.events;
  }

  @Selector()
  static leagues(state: EventsStateModel) {
    return state.events.filter((event) => event.eventType === EventType.League);
  }

  @Selector()
  static fetching(state: EventsStateModel) {
    return state.fetching;
  }

  @Action(EventsAction.LoadEvents)
  loadEvents({ patchState }: StateContext<EventsStateModel>) {
    patchState({ fetching: true });
    return this.eventsService.getEvents().pipe(
      tap((events) => {
        patchState({
          fetching: false,
          events,
        });
      })
    );
  }
}
